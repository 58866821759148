import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import firebase from 'firebase'

export default function DocVeiculo() {
    const [servico] = useState({
        formulario: firebase.firestore().collection("formulario")
    })
    const [proprietario, setProprietario] = useState("");
    const [tipo, setTipo] = useState("");

    const [id, setId] = useState("")
    var metadata = { contentType: 'image/jpeg' };
    const [documento, setDocumento] = useState('');
    const [upload, setUpload] = useState('');
    const [documentoProprietario, setDocumentoProprietario] = useState('');
    const [uploadProprietario, setUploadProprietario] = useState('');

    useEffect(() => {
        const data = localStorage.getItem("id");
        if (data) {
            setId(data);
        }
    }, [servico.formulario])

    function cadastro() {
        if (!upload) {
            mensagemAlerta("Favor escolha o documento do veículo")
            return
        }
        if (!proprietario) {
            mensagemAlerta("Favor diga se o veículo está em seu nome!")
            return
        }
        if (proprietario !== "1") {
            if (!uploadProprietario) {
                mensagemAlerta("Favor escolha o documento do proprietário do veículo ou carro alugado")
                return
            }
        }
        cadastraDocumento();
    }

    function cadastraDocumento() {
        mensagemLoading('Salvando seu documento do Veículo...')
        var uploadTask = firebase.storage().ref().child('formulario/' + id + '/docVeiculo').put(documento[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            // setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                    // setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING:
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servico.formulario.doc(id).update({
                    docVeiculo: downloadURL,
                }).then(() => {
                    Swal.close();
                    if (proprietario === "1") {
                        window.location = "/docBancario"
                    } else {
                        cadastraDocumentoProprietario();
                    }
                })
            });
        });
    }

    function cadastraDocumentoProprietario() {
        mensagemLoading('Salvando seu documento do Proprietário...')
        var uploadTask = firebase.storage().ref().child('formulario/' + id + '/docVeiculoProprietario').put(documentoProprietario[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            // setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                    // setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING:
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servico.formulario.doc(id).update({
                    docVeiculoProprietario: downloadURL,
                    tipo: tipo === "1" ? "Outra Pessoa" : "Alugado"
                }).then(() => {
                    Swal.close();
                    window.location = "/docBancario"
                })
            });
        });
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    const handleDocumento = (event) => {
        event.persist();
        setDocumento(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }
    const handleDocumentoProprietario = (event) => {
        event.persist();
        setDocumentoProprietario(event.target.files)
        setUploadProprietario(URL.createObjectURL(event.target.files[0]))
    }

    return (
        <div className="content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <h3 className="heading mb-6">Documento do Veículo</h3>
                                <p><b>Anexar documento CRLV do veículo referente ao ano vigente</b></p>
                                <p><img src="images/veiculo.jpg" alt="Image" className="img-fluid" /></p>
                            </div>
                            <div className="col-md-6">
                                <div className="row" style={{ textAlign: "center" }}>
                                    <div className="col-md-12 form-group">
                                        {upload ?
                                            <p><img width="200px" height="400px" src={upload} alt="Image" className="img-fluid" /></p>
                                            :
                                            <p><img width="100px" height="100px" src="https://icon-library.com/images/upload-icon-png/upload-icon-png-29.jpg" alt="Image" className="img-fluid" /></p>
                                        }
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <p>Selecionar arquivos em formatos .jpeg, jpg, .png,  .pdf</p>
                                        <input accept="image/*" type="file" onChange={handleDocumento} className="form-control" name="name" id="name" />
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-md-12 form-group">
                                        <p>O veículo está em seu nome, nome de outra pessoa ou é alugado?</p>
                                    </div>
                                    {proprietario === "1" ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Meu Nome"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setProprietario("1")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Meu Nome"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {proprietario === "2" ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Outra Pessoa"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setProprietario("2")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Outra Pessoa"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {proprietario === "3" ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Alugado"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setProprietario("3")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Alugado"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }

                                </div>
                                

                                {proprietario && proprietario !== "1" ?
                                    <div className="row" style={{ textAlign: "center" }}>
                                        <div className="col-md-12 form-group">
                                            {uploadProprietario ?
                                                <p><img width="200px" height="400px" src={uploadProprietario} alt="Image" className="img-fluid" /></p>
                                                :
                                                <p><img width="100px" height="100px" src="https://icon-library.com/images/upload-icon-png/upload-icon-png-29.jpg" alt="Image" className="img-fluid" /></p>
                                            }
                                        </div>
                                        <div className="col-md-12 form-group">
                                            {proprietario === "2" ?
                                                <p><b>Anexar RG como o CPF da pessoa na qual o veículo está registrado</b></p>
                                                :
                                                <p><b>Anexar o contrato de locação do veículo</b></p>
                                            }

                                            <p>Selecionar arquivos em formatos .jpeg, jpg, .png,  .pdf</p>
                                            <input accept="image/*" type="file" onChange={handleDocumentoProprietario} className="form-control" name="name" id="name" />
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                <div className="row">
                                    <div className="col-12">
                                        <input onClick={() => cadastro()} type="button" style={{ backgroundColor: "#088A29" }} value="Salvar e Continuar"
                                            className="btn btn-primary rounded-0 " />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
