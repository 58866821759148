import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import firebase from 'firebase'

export default function Dados() {
    const [servico] = useState({
        formulario: firebase.firestore().collection("formulario")
    })
    const [nome, setNome] = useState("")
    const [ddd, setDDD] = useState("")
    const [celular, setCelular] = useState("")
    const [email, setEmail] = useState("")
    const [dia, setDia] = useState(0)
    const [operacao, setOperacao] = useState({})
    const [id, setId] = useState("")

    useEffect(() => {
        const data = localStorage.getItem("id");
        if (data) {
            const usuario = data;
            setId(data);
            servico.formulario.doc(usuario).get().then((snap) => {
                setNome(snap.data().nome)
                setDDD(snap.data().ddd)
                setCelular(snap.data().celular)
                setEmail(snap.data().email)
                setDia(snap.data().dia)
            })
        }
    }, [servico.formulario])

    function cadastro() {
        if (!nome) {
            mensagemAlerta("Favor diga seu Nome Completo")
            return
        }
        if (!ddd) {
            mensagemAlerta("Favor digite seu DDD")
            return
        }
        if (!celular) {
            mensagemAlerta("Favor digite seu Celular")
            return
        }
        if (!email) {
            mensagemAlerta("Favor digite seu Email")
            return
        }
        // if (!dia) {
        //     mensagemAlerta("Favor escolha o tempo de contrato")
        //     return
        // }
        if (!operacao) {
            mensagemAlerta("Favor escolha a operação desejada")
            return
        }
        mensagemLoading("Salvando seus dados...")
        localStorage.setItem("id", email)
        servico.formulario.where("email", "==", email).where("status", "==", 1).get().then((snap) => {
            if (snap.size > 0) {
                mensagemAlerta("Você já possui um cadastro conosco. Aguarde o retorno de nossos atendentes")
                // servico.formulario.where("status", "==", 1).get().then((sn) => {
                //     console.log(sn.size);
                //     if (sn.size > 0) {
                //         mensagemAlerta("Você já possui um cadastro conosco. Aguarde o retorno de nossos atendentes")
                //     } else {
                //         firebase.firestore().collection("formulario").doc(email).update({
                //             nome: nome,
                //             ddd: ddd,
                //             celular: celular,
                //             email: email,
                //             dia: dia,
                //         }).then(() => {
                //             window.location = "/base"
                //         }).catch(() => {
                //             Swal.close();
                //             mensagemErro("Erro ao continuar com o formulário! Favor tente mais tarde")
                //         })
                //     }
                // })
            } else {
                firebase.firestore().collection("formulario").doc(email).set({
                    nome: nome,
                    ddd: ddd,
                    celular: celular,
                    email: email,
                    // dia: dia,
                    operacao: operacao
                }).then(() => {
                    window.location = "/base"
                }).catch(() => {
                    Swal.close();
                    mensagemErro("Erro ao continuar com o formulário! Favor tente mais tarde")
                })
            }
        })
    }

    function cancelar() {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja cancelar seu cadastro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Cancelando seu cadastro')
                firebase.storage().ref('formulario/' + id + '/antt').delete();
                firebase.storage().ref('formulario/' + id + '/certificado').delete();
                firebase.storage().ref('formulario/' + id + '/cnh').delete();
                firebase.storage().ref('formulario/' + id + '/docVeiculo').delete();
                firebase.storage().ref('formulario/' + id + '/docBancario').delete();
                firebase.storage().ref('formulario/' + id + '/docEndereco').delete();
                firebase.storage().ref('formulario/' + id + '/fotoVeiculoFrente').delete();
                firebase.storage().ref('formulario/' + id + '/fotoVeiculoFundo').delete();
                firebase.storage().ref('formulario/' + id + '/fotoVeiculoBau').delete().then(() => {
                    servico.formulario.doc(id).delete().then(() => {
                        localStorage.setItem("id", "");
                        window.location = "/"
                    })
                })

            }
        });
    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleDDD = (event) => {
        event.persist();
        setDDD(event.target.value);
    }
    const handleCelular = (event) => {
        event.persist();
        setCelular(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div className="content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <h3 className="heading mb-6">Dados Pessoais</h3>
                                <p><b>Vamos ao seus dados pessoais</b></p>
                                <p><img src="images/fotoVeiculo.jpg" alt="Image" className="img-fluid" /></p>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Nome Completo *</label>
                                        <input value={nome} onChange={handleNome} type="text" className="form-control" name="name" id="name" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 form-group">
                                        <label>DDD *</label>
                                        <input max={2} value={ddd} onChange={handleDDD} type="number" className="form-control" name="name" id="name" />
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>Celular *</label>
                                        <input value={celular} onChange={handleCelular} type="number" className="form-control" name="name" id="name" />
                                    </div>
                                    <div className="col-md-5 form-group">
                                        <label>Email *</label>
                                        <input value={email} onChange={handleEmail} type="text" className="form-control" name="name" id="name" />
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Escolha abaixo o tempo de Contrato</label>
                                    </div>
                                </div>
                                <div className="row">
                                    {dia === 30 ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="30 Dias"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setDia(30)} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="30 Dias"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {dia === 60 ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="60 Dias"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setDia(60)} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="60 Dias"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {dia === 180 ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="180 Dias"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setDia(180)} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="180 Dias"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {dia === 360 ?
                                        <div className="col-md-12 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Indeterminado"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-12 form-group">
                                            <input onClick={() => setDia(360)} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Indeterminado"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                </div> */}
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Operação Desejada</label>
                                    </div>
                                </div>
                                <div className="row">
                                    {operacao === "Transferencias Rodoviárias" ?
                                        <div className="col-md-12 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Transferências Rodoviárias"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-12 form-group">
                                            <input onClick={() => setOperacao("Transferencias Rodoviárias")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Transferências Rodoviárias"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {operacao === "Entregas em Moto (Pequenos Volumes)" ?
                                        <div className="col-md-12 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Entregas em Moto (Pequenos Volumes)"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-12 form-group">
                                            <input onClick={() => setOperacao("Entregas em Moto (Pequenos Volumes)")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Entregas em Moto (Pequenos Volumes)"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {operacao === "Abastecimento de Lojas" ?
                                        <div className="col-md-6 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Abastecimento de Lojas"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-6 form-group">
                                            <input onClick={() => setOperacao("Abastecimento de Lojas")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Abastecimento de Lojas"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {operacao === "Puxadas SPOT" ?
                                        <div className="col-md-6 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Puxadas SPOT"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-6 form-group">
                                            <input onClick={() => setOperacao("Puxadas SPOT")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Puxadas SPOT"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {operacao === "Entrega Domiciliar (B2C)" ?
                                        <div className="col-md-6 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Entrega Domiciliar (B2C)"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-6 form-group">
                                            <input onClick={() => setOperacao("Entrega Domiciliar (B2C)")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Entrega Domiciliar (B2C)"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {/* {operacao === "Agente de Cargas" ?
                                        <div className="col-md-6 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Agente de Cargas"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-6 form-group">
                                            <input onClick={() => setOperacao("Agente de Cargas")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Agente de Cargas"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    } */}
                                </div>
                                <div className="row">
                                    <div className="col-md-5 form-group">
                                        <input onClick={() => cadastro()} type="button" style={{ backgroundColor: "#088A29" }} value="Salvar e Continuar"
                                            className="btn btn-primary rounded-0 " />
                                    </div>
                                    {/* <div className="col-md-4 form-group">
                                        <input onClick={() => cancelar()} type="button" style={{ backgroundColor: "red" }} value="Cancelar"
                                            className="btn btn-primary rounded-0 " />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
