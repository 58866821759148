import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import firebase from 'firebase'

export default function DocVeiculo() {
    const [servico] = useState({
        formulario: firebase.firestore().collection("formulario")
    })
    const [id, setId] = useState("")
    var metadata = { contentType: 'image/jpeg' };
    const [documento, setDocumento] = useState('');
    const [upload, setUpload] = useState('');

    useEffect(() => {
        const data = localStorage.getItem("id");
        if (data) {
            const usuario = data;
            setId(data);
            servico.formulario.doc(usuario).get().then((snap) => {
                setUpload(snap.data().documentos ? snap.data().documentos.cnh : null)
            })
        }
    }, [servico.formulario])

    function cadastro() {
        if (!upload) {
            mensagemAlerta("Favor escolha o documento")
            return
        }
        cadastraDocumento();
    }

    function cadastraDocumento() {
        mensagemLoading('Salvando seu documento CNH...')
        var uploadTask = firebase.storage().ref().child('formulario/' + id + '/cnh').put(documento[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                    // setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING:
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servico.formulario.doc(id).update({
                    cnh: downloadURL
                }).then(() => {
                    Swal.close();
                    window.location = "/docVeiculo"
                })
            });
        });
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    const handleDocumento = (event) => {
        event.persist();
        setDocumento(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }

    return (
        <div className="content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <h3 className="heading mb-6">Carteira Nacional de Habilitação</h3>
                                <p><b>Importar a CNH do motorista que irá atuar como agregado</b></p>
                                <p><img src="images/cnh.jpg" alt="Image" className="img-fluid" /></p>
                            </div>
                            <div className="col-md-6">
                                <div className="row" style={{ textAlign: "center" }}>
                                    <div className="col-md-12 form-group">
                                        {upload ?
                                            <p><img width="200px" height="400px" src={upload} alt="Image" className="img-fluid" /></p>
                                            :
                                            <p><img width="100px" height="100px" src="https://icon-library.com/images/upload-icon-png/upload-icon-png-29.jpg" alt="Image" className="img-fluid" /></p>
                                        }
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <p>Selecionar arquivos em formatos .jpeg, jpg, .png,  .pdf</p>
                                        <input accept="image/*" type="file" onChange={handleDocumento} className="form-control" name="name" id="name" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <input onClick={() => cadastro()} type="button" style={{ backgroundColor: "#088A29" }} value="Salvar e Continuar"
                                            className="btn btn-primary rounded-0 " />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
