import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import firebase from 'firebase'

export default function Home() {
    const [servico] = useState({
        formulario: firebase.firestore().collection("formulario")
    })
    const [dado, setDado] = useState({})

    useEffect(() => {
        const data = localStorage.getItem("id");
        if (data) {
            const usuario = data;
            servico.formulario.doc(usuario).get().then((snap) => {
                setDado(snap.data())
            })
        }
    }, [servico.formulario])

    function dados() {
        localStorage.setItem("id", "")
        window.location = "/"
    }

    return (
        <div className="content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <h3 className="heading mb-6">Cadastro Realizado com Sucesso</h3>
                                <p><img src="images/sucesso.jpg" alt="Image" className="img-fluid" /></p>
                            </div>
                            <div className="col-md-6">
                                {/* <p>Então vamos lá:</p> */}
                                <h3 style={{ textAlign: "center" }} className="heading mb-6">{dado.nome}</h3>
                                <h5 style={{ textAlign: "center", marginTop: 20 }}><b>Obrigado por se cadastrar em nosso sistema</b></h5>
                                <h1 style={{ textAlign: "center", marginTop: 30 }}><b>{dado.protocolo}</b></h1>
                                <p style={{ textAlign: "center", }} ><b>Anote acima o seu número de protocolo para acompanhamento</b></p>
                                <div className="row">
                                    <div className="col-12">
                                        <input type="button" onClick={() => dados()} style={{ backgroundColor: "#088A29" }} value="Fechar" className="btn btn-primary rounded-0 py-2 px-4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
