import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'

export default function Home() {

    useEffect(() => {
        localStorage.setItem("id", "");
    }, [])

    function dados() {
        window.location = "/dados"
    }
    function dadosAgente() {
        window.location = "/dadosAgente"
    }

    return (
        <div className="content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <h3 className="heading mb-6">Pré-Cadastro de Agregados</h3>
                                <p>É muito bom saber que você tem interesse em atuar conosco e fazer parte do nosso time de parceiros campeões.</p>
                                <p><img src="images/home.jpeg" alt="Image" className="img-fluid" /></p>
                            </div>
                            <div className="col-md-6">
                                {/* <p>Então vamos lá:</p> */}

                                <p><button type="button" style={{ backgroundColor: "#EEEAE9", width: '100%', height: 150 }} className="btn  rounded-0 py-2 px-4" >1. Primeiro você precisa saber que essa primeira etapa é um pré-cadastro sem compromisso e que vai permitir conhecer melhor você e sua área de atuação.</button></p>
                                <p><button type="button" style={{ backgroundColor: "#EEEAE9", width: '100%', height: 150 }} className="btn  rounded-0 py-2 px-4" >2 - Todas as documentações solicitadas são obrigatórias e devem estar legíveis e atualizadas para que todo nosso processo de cadastros seja o mais rápido possível.</button></p>
                                <p><button type="button" style={{ backgroundColor: "#EEEAE9", width: '100%', height: 150 }} className="btn  rounded-0 py-2 px-4" >3 - O processo correto, é que você faça esse cadastro antes de iniciar suas atividades conosco, caso esteja fazendo após ja ter iniciado, poderá gerar atrasos no seu pagamento, e não queremos isso. Então muita atenção ao processo.</button></p>
                                <p><button type="button" style={{ backgroundColor: "#EEEAE9", width: '100%', height: 150 }} className="btn  rounded-0 py-2 px-4" >4 - Vamos retornar a você o resultado do pré-cadastro, informando tabelas de frete ou negociações. Mas fique tranquilo que isso será repassado pelo nosso time de operação.</button></p>

                                <p><b>Qualquer dúvida, pode acionar nossa torre de controle no contato (98) 98542-8105</b></p>
                                <div className="row">
                                    <div className="col-12">
                                        <input type="button" onClick={() => dados()} style={{ backgroundColor: "#088A29",width:"100%" }} value="Iniciar Cadastro" className="btn btn-primary rounded-0 py-2 px-4" />
                                    </div>
                                    {/* <div className="col-6">
                                        <input type="button" onClick={() => dadosAgente()} style={{ backgroundColor: "#088A29" }} value="Cadastrar como Agente" className="btn btn-primary rounded-0 py-2 px-4" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
