import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import firebase from 'firebase'
import InputMask from 'react-input-mask';
import moment from 'moment';
import { buscaEndereco } from '../../services/Localidade';

export default function DadosAgente() {
    const [servico] = useState({
        posto: firebase.firestore().collection("posto")
    })
    const [idDocumento, setIdocumento] = useState("")
    const [rastreado, setRastreado] = useState("")
    const [tipo, setTipo] = useState("")
    const [placa, setPlaca] = useState("")
    const [ano, setAno] = useState("")

    const [documento, setDocumento] = useState("")

    const [upload, setUpload] = useState("")
    var metadata = { contentType: 'image/jpeg' };

    useEffect(() => {
        const dado = localStorage.getItem('idDocumento');
        setIdocumento(dado)
    }, [servico.posto])

    function imagemFrente(id) {
        mensagemLoading('Salvando a imagem do Veículo...')
        var uploadTask = firebase.storage().ref().child('agente/' + idDocumento + '/' + id).put(documento[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                    break;
                case firebase.storage.TaskState.RUNNING:
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servico.posto.doc(id).update({
                    frente: downloadURL
                }).then(() => {
                    Swal.close();
                })
            });
        });
    }

    function cadastro() {

    }

    const handleTipo = (event) => {
        event.persist();
        setTipo(event.target.value);
    }
    const handlePlaca = (event) => {
        event.persist();
        setPlaca(event.target.value);
    }
    const handleAno = (event) => {
        event.persist();
        setAno(event.target.value);
    }
    const handleRastreado = (event) => {
        event.persist();
        setRastreado(event.target.value);
    }

    const handleDocumento = (event) => {
        event.persist();
        setDocumento(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div className="content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <h3 className="heading mb-6">Dados dos Caminhões</h3>
                                <p><b>Cadastre seus caminhões</b></p>
                                <p><img src="images/page.jpeg" alt="Image" className="img-fluid" /></p>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Tipo do veículo </label>
                                    </div>
                                    {tipo === "fiorino" ?
                                        <div className="col-md-3 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Fiorino"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-3 form-group">
                                            <input onClick={() => setTipo("fiorino")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Fiorino"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {tipo === "3/4" ?
                                        <div className="col-md-3 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="3/4"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-3 form-group">
                                            <input onClick={() => setTipo("3/4")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="3/4"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {tipo === "toco" ?
                                        <div className="col-md-3 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Toco"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-3 form-group">
                                            <input onClick={() => setTipo("toco")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Toco"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {tipo === "tuck" ?
                                        <div className="col-md-3 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Truck"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-3 form-group">
                                            <input onClick={() => setTipo("tuck")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Truck"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {tipo === "carreta" ?
                                        <div className="col-md-3 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Carreta"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-3 form-group">
                                            <input onClick={() => setTipo("carreta")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Carreta"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }

                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-group">
                                        <label>Placa *</label>
                                        <InputMask mask="aaaa-999" maskChar={null} className="form-control" value={placa} onChange={handlePlaca} />
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>Ano de Fábrica *</label>
                                        <InputMask mask="9999" maskChar={null} className="form-control" value={ano} onChange={handleAno} />
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>Img do veículo? *</label>
                                        <input accept="image/*" type="file" onChange={handleDocumento} className="form-control" name="name" id="name" />
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label>Rastreado? *</label>
                                    </div>
                                    {rastreado === "SIM" ?
                                        <div className="col-md-3 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="SIM"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-3 form-group">
                                            <input onClick={() => setRastreado("SIM")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="SIM"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {rastreado === "NAO" ?
                                        <div className="col-md-3 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="NÃO"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-3 form-group">
                                            <input onClick={() => setRastreado("NAO")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="NÃO"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-md-5 form-group">
                                        <input onClick={() => cadastro()} type="button" style={{ backgroundColor: "#088A29" }} value="Adicionar"
                                            className="btn btn-primary rounded-0 " />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label><b>Veículos Cadastrados</b></label>
                                    </div>
                                </div>
                                <div className="row">

                                </div>
                                <div className="row">
                                    <div className="col-md-5 form-group">
                                        <input onClick={() => cadastro()} type="button" style={{ backgroundColor: "#088A29" }} value="Guardar e Continuar"
                                            className="btn btn-primary rounded-0 " />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
