import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import firebase from 'firebase'


export default function Base() {
    const [cidade, setCidade] = useState("")
    const [cidadeExtra, setCidadeExtra] = useState("")
    const [veiculo, setVeiculo] = useState("")
    const [id, setId] = useState("")
    const [servico] = useState({
        formulario: firebase.firestore().collection("formulario")
    })

    useEffect(() => {
        const data = localStorage.getItem("id");
        if (data) {
            const usuario = data;
            setId(usuario)
            servico.formulario.doc(usuario).get().then((snap) => {
                setCidade(snap.data().cidade)
                setVeiculo(snap.data().veiculo)
            })
        }
    }, [servico.formulario])

    function cadastro() {
        if (!cidade) {
            mensagemAlerta("Favor escolha a Base de atendimento")
            return
        }
        if (cidade === "outra") {
            if (!cidadeExtra) {
                mensagemAlerta("Favor digite a Base ")
                return
            }
        }
        if (!veiculo) {
            mensagemAlerta("Favor escollha o veículo")
            return
        }
        mensagemLoading("Salvando seus dados...")
        servico.formulario.doc(id).update({
            cidade: cidade === "outra" ? cidadeExtra : cidade,
            veiculo: veiculo
        }).then(() => {
            if (veiculo === "caminhao") {
                window.location = "/docANTT"
            } else {
                window.location = "/docCNH"
            }
        }).catch(() => {
            Swal.close();
            mensagemAlerta("Erro ao enviar informações! Favor contate nossa administração")
        })
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    const handleCidade = (event) => {
        event.persist();
        setCidadeExtra(event.target.value);
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div className="content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <h3 className="heading mb-6">Dados Profissionais</h3>
                                <p><b>Agora escolha seus dados profissionais</b></p>
                                <p><img src="images/base.jpg" alt="Image" className="img-fluid" /></p>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Escolha a Base de atendimento do Entrega Já </label>
                                    </div>
                                </div>
                                <div className="row">
                                    {cidade === "imperatriz" ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Imperatriz"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setCidade("imperatriz")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Imperatriz"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {cidade === "sao luis" ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="São Luis"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setCidade("sao luis")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="São Luis"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {cidade === "maraba" ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Marabá"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setCidade("maraba")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Marabá"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {cidade === "guarulhos" ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Guarulhos"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setCidade("guarulhos")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Guarulhos"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {cidade === "belem" ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Belém"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setCidade("belem")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Belém"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {cidade === "teresina" ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Teresina"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setCidade("teresina")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Teresina"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {/* {cidade === "outra" ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Outra Opçãol"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setCidade("outra")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Outra Opção"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    } */}
                                </div>
                                {/* {cidade === "outra" ?
                                    <div className="row">
                                        <div className="col-md-12 form-group">
                                            <label>Digite o Lugar </label>
                                            <input type="text" onChange={handleCidade} value={cidadeExtra} className="form-control" name="name" id="name" />
                                        </div>
                                    </div>
                                    :
                                    null
                                } */}
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Seu Veículo </label>
                                    </div>
                                </div>
                                <div className="row">
                                    {veiculo === "carro passeio" ?
                                        <div className="col-md-6 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Carro passeio"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-6 form-group">
                                            <input onClick={() => setVeiculo("carro passeio")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Carro passeio"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {veiculo === "caminhao" ?
                                        <div className="col-md-6 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Caminhão"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-6 form-group">
                                            <input onClick={() => setVeiculo("caminhao")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Caminhão"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {veiculo === "moto" ?
                                        <div className="col-md-6 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Moto"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-6 form-group">
                                            <input onClick={() => setVeiculo("moto")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Moto"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {veiculo === "carreta" ?
                                        <div className="col-md-6 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Carreta"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-6 form-group">
                                            <input onClick={() => setVeiculo("carreta")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Carreta"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {/* {veiculo === "kombi" ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Kombi / Van"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setVeiculo("kombi")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Kombi / Van"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {veiculo === "carro utilitario" ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Carro Utilitário"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setVeiculo("carro utilitario")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Carro Utilitário"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {veiculo === "caminhao" ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Caminhão"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setVeiculo("caminhao")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Caminhão"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {veiculo === "carreta" ?
                                        <div className="col-md-4 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Carreta"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-4 form-group">
                                            <input onClick={() => setVeiculo("carreta")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Carreta"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {veiculo === "caminhao toco" ?
                                        <div className="col-md-6 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Caminhão Toco"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-6 form-group">
                                            <input onClick={() => setVeiculo("caminhao toco")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Caminhão Toco"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {veiculo === "caminhao truck" ?
                                        <div className="col-md-6 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="Caminhão Truck"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-6 form-group">
                                            <input onClick={() => setVeiculo("caminhao truck")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="Caminhão Truck"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    } */}
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <input onClick={() => cadastro()} type="button" style={{ backgroundColor: "#088A29" }} value="Salvar e Continuar"
                                            className="btn btn-primary rounded-0 " />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
