import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import firebase from 'firebase'
import moment from 'moment';

export default function Dados() {
    const [servico] = useState({
        formulario: firebase.firestore().collection("formulario")
    })
    const [id, setId] = useState("")
    const [dado, setDado] = useState({})

    useEffect(() => {
        const data = localStorage.getItem("id");
        if (data) {
            const usuario = data;
            setId(usuario);
            servico.formulario.doc(usuario).get().then((snap) => {
                setDado(snap.data())
            })
        }
    }, [servico.formulario])

    function cadastro() {
        mensagemLoading("Salvando alterações ...")
        firebase.firestore().collection("formulario").doc(id).update({
            status: 1,
            protocolo: "EJ" + Math.floor(10000 + Math.random() * 900000),
            dataCadastro: moment().format("YYYY-MM-DD HH:mm")
        }).then(() => {
            window.location = "/sucesso"
        }).catch(() => {
            mensagemErro("Erro ao confirmar seu cadastro")
        })
    }
    function cancelar() {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja cancelar seu cadastro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Cancelando seu cadastro')
                firebase.storage().ref('formulario/' + id + '/antt').delete();
                firebase.storage().ref('formulario/' + id + '/certificado').delete();
                firebase.storage().ref('formulario/' + id + '/cnh').delete();
                firebase.storage().ref('formulario/' + id + '/docVeiculo').delete();
                firebase.storage().ref('formulario/' + id + '/docBancario').delete();
                firebase.storage().ref('formulario/' + id + '/docEndereco').delete();
                firebase.storage().ref('formulario/' + id + '/fotoVeiculoFrente').delete();
                firebase.storage().ref('formulario/' + id + '/fotoVeiculoFundo').delete();
                firebase.storage().ref('formulario/' + id + '/fotoVeiculoBau').delete().then(() => {
                    servico.formulario.doc(id).delete().then(() => {
                        localStorage.setItem("id", "");
                        window.location = "/"
                    })
                })

            }
        });
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div className="content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <h3 className="heading mb-6">Confirme Seus Dados</h3>
                                <p><b>Confirma se seus dados estão todos em ordens. Caso não volte e edite novamente</b></p>
                                <p><img src="images/lista.jpeg" alt="Image" className="img-fluid" /></p>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-8 form-group">
                                        <label>Nome Completo</label>
                                        {/* <input value={nome} onChange={handleNome} type="text" className="form-control" name="name" id="name" /> */}
                                        <p><b>{dado.nome}</b></p>
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>Celular</label>
                                        {/* <input value={nome} onChange={handleNome} type="text" className="form-control" name="name" id="name" /> */}
                                        <p><b>{dado.ddd} {dado.celular}</b></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 form-group">
                                        <label>Email</label>
                                        <p><b>{dado.email}</b></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-group">
                                        <label>Veículo</label>
                                        <p><b>{dado.veiculo}</b></p>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Base de Atendimento</label>
                                        <p><b>{dado.cidade}</b></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label>Obserações</label>
                                        <p><b>Todos os seus arquivos foram salvos</b></p>
                                        <p><b>Guarde seu Email, pois ele será sua chave</b></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8 form-group">
                                        <input onClick={() => cadastro()} type="button" style={{ backgroundColor: "#088A29" }} value="Aceitar e salvar minhas informações"
                                            className="btn btn-primary rounded-0 " />
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <input onClick={() => cancelar()} type="button" style={{ backgroundColor: "red" }} value="Cancelar"
                                            className="btn btn-primary rounded-0 " />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
