import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import firebase from 'firebase'


export default function DocVeiculo() {
    const [servico] = useState({
        formulario: firebase.firestore().collection("formulario")
    })
    const [id, setId] = useState("")
    var metadata = { contentType: 'image/jpeg' };

    const [frente, setFrente] = useState('');
    const [fundo, setFundo] = useState('');
    const [bau, setBau] = useState('');

    const [uploadFrente, setUploadFrente] = useState('');
    const [uploadFundo, setUploadFundo] = useState('');
    const [uploadBau, setUploadBau] = useState('');

    useEffect(() => {
        const data = localStorage.getItem("id");
        if (data) {
            const usuario = data;
            setId(data);
        }
    }, [servico.formulario])

    function cadastro() {
        if (!uploadFrente) {
            mensagemAlerta("Favor escolha a foto de Frente do Veículo")
            return
        }
        if (!uploadFundo) {
            mensagemAlerta("Favor escolha a foto de Fundo do Veículo")
            return
        }
        if (!uploadBau) {
            mensagemAlerta("Favor escolha a foto do baú do veículo")
            return
        }
        cadastraFrente();
    }

    function cadastraFrente() {
        mensagemLoading('Salvando a Foto da Frente...')
        var uploadTask = firebase.storage().ref().child('formulario/' + id + '/fotoVeiculoFrente').put(frente[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                    // setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING:
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servico.formulario.doc(id).update({
                    fotoVeiculoFrente: downloadURL
                }).then(() => {
                    Swal.close();
                    cadastraFundo();
                })
            });
        });
    }

    function cadastraFundo() {
        mensagemLoading('Salvando a Foto do Fundo...')
        var uploadTask = firebase.storage().ref().child('formulario/' + id + '/fotoVeiculoFundo').put(fundo[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                    // setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING:
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servico.formulario.doc(id).update({
                    fotoVeiculoFundo: downloadURL
                }).then(() => {
                    Swal.close();
                    cadastraBau();
                })
            });
        });
    }

    function cadastraBau() {
        mensagemLoading('Salvando a Foto do Baú...')
        var uploadTask = firebase.storage().ref().child('formulario/' + id + '/fotoVeiculoBau').put(bau[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                    // setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING:
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servico.formulario.doc(id).update({
                    fotoVeiculoBau: downloadURL
                }).then(() => {
                    Swal.close();
                    window.location = "/docCnpj"
                })
            });
        });
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    const handleFrente = (event) => {
        event.persist();
        setFrente(event.target.files)
        setUploadFrente(URL.createObjectURL(event.target.files[0]))
    }
    const handleFundo = (event) => {
        event.persist();
        setFundo(event.target.files)
        setUploadFundo(URL.createObjectURL(event.target.files[0]))
    }
    const handleBau = (event) => {
        event.persist();
        setBau(event.target.files)
        setUploadBau(URL.createObjectURL(event.target.files[0]))
    }

    return (
        <div className="content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <h3 className="heading mb-6">Fotos do Veiculo</h3>
                                <p><b>Obrigatório foto de frente, foto de fundo do veículo e foto do baú aberto do veículo</b></p>
                                <p><img src="images/fotoVeiculo.jpg" alt="Image" className="img-fluid" /></p>
                            </div>
                            <div className="col-md-6">
                                <div className="row" style={{ textAlign: "center" }}>
                                    <div className="col-md-12 form-group">
                                        {uploadFrente ?
                                            <p><img width="200px" height="400px" src={uploadFrente} alt="Image" className="img-fluid" /></p>
                                            :
                                            <p><img width="100px" height="100px" src="https://icon-library.com/images/upload-icon-png/upload-icon-png-29.jpg" alt="Image" className="img-fluid" /></p>
                                        }
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <p><b>FOTO DA FRENTE DO VEÍCULO</b></p>
                                        <input accept="image/*" type="file" onChange={handleFrente} className="form-control" name="name" id="name" />
                                    </div>
                                </div>
                                <div className="row" style={{ textAlign: "center" }}>
                                    <div className="col-md-12 form-group">
                                        {uploadFundo ?
                                            <p><img width="200px" height="400px" src={uploadFundo} alt="Image" className="img-fluid" /></p>
                                            :
                                            <p><img width="100px" height="100px" src="https://icon-library.com/images/upload-icon-png/upload-icon-png-29.jpg" alt="Image" className="img-fluid" /></p>
                                        }
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <p><b>FOTO DO FUNDO DO VEÍCULO</b></p>
                                        <input accept="image/*" type="file" onChange={handleFundo} className="form-control" name="name" id="name" />
                                    </div>
                                </div>
                                <div className="row" style={{ textAlign: "center" }}>
                                    <div className="col-md-12 form-group">
                                        {uploadBau ?
                                            <p><img width="200px" height="400px" src={uploadBau} alt="Image" className="img-fluid" /></p>
                                            :
                                            <p><img width="100px" height="100px" src="https://icon-library.com/images/upload-icon-png/upload-icon-png-29.jpg" alt="Image" className="img-fluid" /></p>
                                        }
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <p><b>FOTO DO BAÚ DO VEÍCULO</b></p>
                                        <input accept="image/*" type="file" onChange={handleBau} className="form-control" name="name" id="name" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <input onClick={() => cadastro()} type="button" style={{ backgroundColor: "#088A29" }} value="Salvar e Continuar"
                                            className="btn btn-primary rounded-0 " />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
