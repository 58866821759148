import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import firebase from 'firebase'
import InputMask from 'react-input-mask';
import moment from 'moment';
import { buscaEndereco } from '../../services/Localidade';

export default function DadosAgente() {
    const [servico] = useState({
        posto: firebase.firestore().collection("posto")
    })
    const [cnpj, setCnpj] = useState("")
    const [inscricao, setInscricao] = useState("")
    const [razaoSocial, setRazaoSocial] = useState("")
    const [nomeFantasia, setNomeFantasia] = useState("")
    const [telefone, setTelefone] = useState("")
    const [celular, setCelular] = useState("")
    const [email, setEmail] = useState("")

    const [cep, setCep] = useState("")
    const [endereco, setEndereco] = useState("")
    const [bairro, setBairro] = useState("")
    const [numero, setNumero] = useState("")
    const [cidade, setCidade] = useState("")
    const [uf, setUf] = useState("")
    const [complemento, setComplemento] = useState("")


    const [area, setArea] = useState("")
    const [internet, setInternet] = useState("")
    const [doca, setDoca] = useState("")
    const [perimetro, setPerimetro] = useState("")
    const [cerca, setCerca] = useState("")
    const [id, setId] = useState("")

    const [frente, setFrente] = useState("")
    const [deposito, setDeposito] = useState("")
    const [escritorio, setEscritorio] = useState("")

    const [uploadFrente, setUploadFrente] = useState("")
    const [uploadDeposito, setUploadDeposito] = useState("")
    const [uploadEscritorio, setUploadEscritorio] = useState("")
    var metadata = { contentType: 'image/jpeg' };

    useEffect(() => {

    }, [servico.posto])

    function imagemFrente(id) {
        mensagemLoading('Salvando a imagem da Frente...')
        var uploadTask = firebase.storage().ref().child('agente/' + id + '/frente').put(frente[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                    break;
                case firebase.storage.TaskState.RUNNING:
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servico.posto.doc(id).update({
                    frente: downloadURL
                }).then(() => {
                    Swal.close();
                    imagemDeposito(id)
                })
            });
        });
    }

    function imagemDeposito(id) {
        mensagemLoading('Salvando a imagem do Depósito...')
        var uploadTask = firebase.storage().ref().child('agente/' + id + '/deposito').put(deposito[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                    break;
                case firebase.storage.TaskState.RUNNING:
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servico.posto.doc(id).update({
                    deposito: downloadURL
                }).then(() => {
                    Swal.close();
                    imagemEscritorio(id)
                })
            });
        });
    }
    function imagemEscritorio(id) {
        mensagemLoading('Salvando a imagem do Escritório...')
        var uploadTask = firebase.storage().ref().child('agente/' + id + '/escritorio').put(escritorio[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                    break;
                case firebase.storage.TaskState.RUNNING:
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servico.posto.doc(id).update({
                    escritorio: downloadURL
                }).then(() => {
                    Swal.close();
                    window.location = "/docCertificado"
                })
            });
        });
    }

    function cadastro() {
        if (!cnpj) {
            mensagemAlerta("Favor digite o CNPJ")
            return
        }
        if (!inscricao) {
            mensagemAlerta("Favor digite a Inscrição Estadual")
            return
        }
        if (!razaoSocial) {
            mensagemAlerta("Favor digite a Razão Social")
            return
        }
        if (!nomeFantasia) {
            mensagemAlerta("Favor digite o Nome Fantasia")
            return
        }
        if (!celular) {
            mensagemAlerta("Favor digite o Celular")
            return
        }
        if (!email) {
            mensagemAlerta("Favor digite o Celular")
            return
        }
        if (!cep) {
            mensagemAlerta("Favor digite o CEP")
            return
        }
        if (!endereco) {
            mensagemAlerta("Favor digite o Endereço")
            return
        }
        if (!bairro) {
            mensagemAlerta("Favor digite o Bairro")
            return
        }
        if (!cidade) {
            mensagemAlerta("Favor digite a Cidade")
            return
        }
        if (!uf) {
            mensagemAlerta("Favor digite o Estado com as siglas")
            return
        }
        if (!area) {
            mensagemAlerta("Favor digite a área de armazenagem")
            return
        }
        if (!doca) {
            mensagemAlerta("Diga-nos se o seu espaço possui docas")
            return
        }
        if (!internet) {
            mensagemAlerta("Informe se você possui computador com internet")
            return
        }
        if (!perimetro) {
            mensagemAlerta("Existe barreira no perímetro?")
            return
        }
        if (!cerca) {
            mensagemAlerta("Existe cerca elétrica , arame ou infravermelho?")
            return
        }
        if (!frente) {
            mensagemAlerta("Favor escolha a imagem da Frente")
            return
        }
        if (!deposito) {
            mensagemAlerta("Favor escolha a imagem do Depósito")
            return
        }
        if (!escritorio) {
            mensagemAlerta("Favor escolha a imagem do Escritório")
            return
        }

        mensagemLoading("Salvando seus dados...")
        servico.posto.where("cnpj", "==", cnpj).get().then((snap) => {
            if (snap.size > 0) {
                servico.posto.where("cnpj", "==", cnpj).where("status", "==", "RECEBIDO").get().then((snapRecebido) => {
                    if (snapRecebido.size > 0) {
                        mensagemAlerta("Você já possui um cadastro conosco. Aguarde o retorno de nossos atendentes")
                    } else {
                        console.log(snap.docs[0].data())
                    }
                })
            } else {
                servico.posto.add({
                    cnpj: cnpj,
                    inscricao: inscricao,
                    razaoSocial: razaoSocial,
                    nomeFantasia: nomeFantasia,
                    celular: celular,
                    endereco: {
                        cep: cep,
                        endereco: endereco,
                        bairro: bairro,
                        numero: numero,
                        cidade: cidade,
                        uf: uf,
                        complemento: complemento
                    },
                    area: area,
                    doca: doca,
                    internet: internet,
                    perimetro: perimetro,
                    cerca: cerca,
                    email: email,
                    dataCadastro: moment().format("YYYY-MM-DD"),
                    dataAtualizacao: moment().format("YYYY-MM-DD HH:mm"),
                    dataFull: moment().format("YYYY-MM-DD HH:mm"),
                }).then((docRef) => {
                    servico.posto.doc(docRef.id).update({
                        id: docRef.id
                    }).then(() => {
                        imagemFrente(docRef.id)
                        // mensagemSucesso("Cadastrado com sucesso")
                    }).catch(() => {
                        Swal.close();
                        mensagemErro("Erro ao continuar com o formulário! Favor tente mais tarde")
                    })
                }).catch(() => {
                    Swal.close();
                    mensagemErro("Erro ao continuar com o formulário! Favor tente mais tarde")
                })
            }
        })
    }

    const handleCnpj = (event) => {
        event.persist();
        setCnpj(event.target.value);
    }
    const handleRazaoSocial = (event) => {
        event.persist();
        setRazaoSocial(event.target.value);
    }
    const handleNomeFantasia = (event) => {
        event.persist();
        setNomeFantasia(event.target.value);
    }
    const handleInscricao = (event) => {
        event.persist();
        setInscricao(event.target.value);
    }
    const handleTelefone = (event) => {
        event.persist();
        setTelefone(event.target.value);
    }
    const handleCelular = (event) => {
        event.persist();
        setCelular(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }

    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
        if (event.target.value.length === 8) {
            mensagemLoading("Buscando Endereço")
            buscaEndereco(event.target.value).then((doc) => {
                Swal.close();
                if (doc.erro === true) {
                    setEndereco("")
                    setBairro("")
                    setCidade("")
                    setUf("")
                    mensagemAlerta('Não achamos seu CEP! Favor verifique novamente ou digite manualmente')
                } else {
                    setEndereco(doc.logradouro)
                    setBairro(doc.bairro)
                    setCidade(doc.localidade)
                    setUf(doc.uf)
                }
            })
        }
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBairro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleCidade = (event) => {
        event.persist();
        setCidade(event.target.value);
    }
    const handleUf = (event) => {
        event.persist();
        setUf(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }

    const handleArea = (event) => {
        event.persist();
        setArea(event.target.value);
    }

    const handleFrente = (event) => {
        event.persist();
        setFrente(event.target.files)
        setUploadFrente(URL.createObjectURL(event.target.files[0]))
    }
    const handleDeposito = (event) => {
        event.persist();
        setDeposito(event.target.files)
        setUploadDeposito(URL.createObjectURL(event.target.files[0]))
    }
    const handleEscritorio = (event) => {
        event.persist();
        setEscritorio(event.target.files)
        setUploadEscritorio(URL.createObjectURL(event.target.files[0]))
    }
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }

    return (
        <div className="content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <h3 className="heading mb-6">Dados do Posto</h3>
                                <p><b>Dados do Posto avançado</b></p>
                                <p><img src="images/page.jpeg" alt="Image" className="img-fluid" /></p>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <label>CNPJ *</label>
                                        <InputMask mask="99.999.999/9999-99" maskChar={null} className="form-control" value={cnpj} onChange={handleCnpj} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Inscrição Estadual *</label>
                                        <input value={inscricao} onChange={handleInscricao} type="text" className="form-control" name="name" id="name" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <label>Razão Social *</label>
                                        <input value={razaoSocial} onChange={handleRazaoSocial} type="text" className="form-control" name="name" id="name" />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Nome Fantasia *</label>
                                        <input value={nomeFantasia} onChange={handleNomeFantasia} type="text" className="form-control" name="name" id="name" />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Telefone Fixo </label>
                                        <InputMask mask="99 9999-9999" maskChar={null} className="form-control" value={telefone} onChange={handleTelefone} />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label>Celular *</label>
                                        <InputMask mask="99 99999-9999" maskChar={null} className="form-control" value={celular} onChange={handleCelular} />
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label>Email *</label>
                                        <input value={email} onChange={handleEmail} type="text" className="form-control" name="name" id="name" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label><b>Dados de Endereço</b></label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 form-group">
                                        <label>CEP *</label>
                                        <InputMask mask="99999999" maskChar={null} className="form-control" value={cep} onChange={handleCep} />
                                    </div>
                                    <div className="col-md-8 form-group">
                                        <label>Endereço *</label>
                                        <input value={endereco} onChange={handleEndereco} type="text" className="form-control" name="name" id="name" />
                                    </div>
                                    <div className="col-md-10 form-group">
                                        <label>Bairro *</label>
                                        <input value={bairro} onChange={handleBairro} type="text" className="form-control" name="name" id="name" />
                                    </div>
                                    <div className="col-md-2 form-group">
                                        <label>N°</label>
                                        <InputMask mask="9999" maskChar={null} className="form-control" value={numero} onChange={handleNumero} />
                                    </div>
                                    <div className="col-md-10 form-group">
                                        <label>Cidade *</label>
                                        <input value={cidade} onChange={handleCidade} type="text" className="form-control" name="name" id="name" />
                                    </div>
                                    <div className="col-md-2 form-group">
                                        <label>UF *</label>
                                        <input value={uf} onChange={handleUf} type="text" className="form-control" name="name" id="name" />
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label>Complemento</label>
                                        <input value={complemento} onChange={handleComplemento} type="text" className="form-control" name="name" id="name" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <label><b>Dados da Base</b></label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 form-group">
                                        <label>Área de Armaz. (M²)</label>
                                        <input value={area} onChange={handleArea} type="text" className="form-control" name="name" id="name" />
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label>Possui Doca?</label>
                                    </div>
                                    {doca === "SIM" ?
                                        <div className="col-md-3 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="SIM"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-3 form-group">
                                            <input onClick={() => setDoca("SIM")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="SIM"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {doca === "NAO" ?
                                        <div className="col-md-3 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="NÃO"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-3 form-group">
                                            <input onClick={() => setDoca("NAO")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="NÃO"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    <div className="col-md-12 form-group">
                                        <label>Computador com acesso a Internet?</label>
                                    </div>
                                    {internet === "SIM" ?
                                        <div className="col-md-3 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="SIM"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-3 form-group">
                                            <input onClick={() => setInternet("SIM")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="SIM"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {internet === "NAO" ?
                                        <div className="col-md-3 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="NÃO"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-3 form-group">
                                            <input onClick={() => setInternet("NAO")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="NÃO"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    <div className="col-md-12 form-group">
                                        <label>Barreira Perimetral - Muro que impeça a visualizção?</label>
                                    </div>
                                    {perimetro === "SIM" ?
                                        <div className="col-md-3 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="SIM"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-3 form-group">
                                            <input onClick={() => setPerimetro("SIM")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="SIM"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {perimetro === "NAO" ?
                                        <div className="col-md-3 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="NÃO"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-3 form-group">
                                            <input onClick={() => setPerimetro("NAO")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="NÃO"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    <div className="col-md-12 form-group">
                                        <label>Cerca elétrica - Arame ou Infravermelho ativo?</label>
                                    </div>
                                    {cerca === "SIM" ?
                                        <div className="col-md-3 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="SIM"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-3 form-group">
                                            <input onClick={() => setCerca("SIM")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="SIM"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                    {cerca === "NAO" ?
                                        <div className="col-md-3 form-group">
                                            <input type="submit" style={{ backgroundColor: "#58ACFA" }} value="NÃO"
                                                className="btn btn-primary form-control " />
                                        </div>
                                        :
                                        <div className="col-md-3 form-group">
                                            <input onClick={() => setCerca("NAO")} type="submit" style={{ backgroundColor: "#BDBDBD" }} value="NÃO"
                                                className="btn btn-primary form-control " />
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="row" style={{ textAlign: "center" }}>
                                            <div className="col-md-12 form-group">
                                                {uploadFrente ?
                                                    <p><img width="200px" height="400px" src={uploadFrente} alt="Image" className="img-fluid" /></p>
                                                    :
                                                    <p><img width="100px" height="100px" src="https://icon-library.com/images/upload-icon-png/upload-icon-png-29.jpg" alt="Image" className="img-fluid" /></p>
                                                }
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <p><b>Imagem da Frente</b></p>
                                                <input accept="image/*" type="file" onChange={handleFrente} className="form-control" name="name" id="name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row" style={{ textAlign: "center" }}>
                                            <div className="col-md-12 form-group">
                                                {uploadDeposito ?
                                                    <p><img width="200px" height="400px" src={uploadDeposito} alt="Image" className="img-fluid" /></p>
                                                    :
                                                    <p><img width="100px" height="100px" src="https://icon-library.com/images/upload-icon-png/upload-icon-png-29.jpg" alt="Image" className="img-fluid" /></p>
                                                }
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <p><b>Imagem do Depósito</b></p>
                                                <input accept="image/*" type="file" onChange={handleDeposito} className="form-control" name="name" id="name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row" style={{ textAlign: "center" }}>
                                            <div className="col-md-12 form-group">
                                                {uploadEscritorio ?
                                                    <p><img width="200px" height="400px" src={uploadEscritorio} alt="Image" className="img-fluid" /></p>
                                                    :
                                                    <p><img width="100px" height="100px" src="https://icon-library.com/images/upload-icon-png/upload-icon-png-29.jpg" alt="Image" className="img-fluid" /></p>
                                                }
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <p><b>Imagem do Escritório</b></p>
                                                <input accept="image/*" type="file" onChange={handleEscritorio} className="form-control" name="name" id="name" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-5 form-group">
                                        <input onClick={() => cadastro()} type="button" style={{ backgroundColor: "#088A29" }} value="Guardar e Continuar"
                                            className="btn btn-primary rounded-0 " />
                                    </div>
                                    {/* <div className="col-md-4 form-group">
                                        <input onClick={() => cancelar()} type="button" style={{ backgroundColor: "red" }} value="Cancelar"
                                            className="btn btn-primary rounded-0 " />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
