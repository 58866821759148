import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom'

// Formulário
import Home from './src/app/Home'
import Dados from './src/app/Dados'
import Base from './src/app/Base'
import Confirmacao from './src/app/Confirmacao'
import Sucesso from './src/app/Sucesso'

// Documentos
import DocBancario from './src/app/documentos/DocBancario'
import DocVeiculo from './src/app/documentos/DocVeiculo'
import DocCnpj from './src/app/documentos/DocCnpj'
import DocCNH from './src/app/documentos/DocCNH'
import DocANTT from './src/app/documentos/DocANTT'
import DocCertificado from './src/app/documentos/DocCertificado'
import DocEndereco from './src/app/documentos/DocEndereco'
import DocFotosVeiculo from './src/app/documentos/DocFotosVeiculo'

// Formulário - Agente
import DadosAgente from './src/app/agente/Dados'
import DadosCaminhoes from './src/app/agente/Caminhoes'


export default class Routes extends Component {
    render() {
        return (
            <div className="App">
                <BrowserRouter>

                    {/* Formulário */}
                    <Route path="/" exact={true} component={Home} />
                    <Route path="/dados" exact={true} component={Dados} />
                    <Route path="/base" exact={true} component={Base} />
                    <Route path="/confirmacao" exact={true} component={Confirmacao} />
                    <Route path="/sucesso" exact={true} component={Sucesso} />
                    
                    {/* Documentos */}
                    <Route path="/docANTT" exact={true} component={DocANTT} />
                    <Route path="/docCertificado" exact={true} component={DocCertificado} />
                    <Route path="/docCNH" exact={true} component={DocCNH} />
                    <Route path="/docVeiculo" exact={true} component={DocVeiculo} />
                    <Route path="/docBancario" exact={true} component={DocBancario} />
                    <Route path="/docEndereco" exact={true} component={DocEndereco} />
                    <Route path="/docFotosVeiculo" exact={true} component={DocFotosVeiculo} />
                    <Route path="/docCnpj" exact={true} component={DocCnpj} />

                    {/* Formulário Agentes */}
                    <Route path="/dadosAgente" exact={true} component={DadosAgente} />
                    <Route path="/dadosCaminhoes" exact={true} component={DadosCaminhoes} />

                </BrowserRouter>
            </div>
        );
    }
}